const sliders = {
    'recommendation-items-slider': {
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="btn prev"><span class="icon icon-left"></span></button>',
        nextArrow: '<button class="btn next"><span class="icon icon-right"></span></button>',
    }
}

$.each(sliders, (name, options) => {
    const slider = $('.' + name)

    if(slider.length) {
        slider.slick(options)
    }
})